<template>
	<div :class="{ contain: true, mobile: isMobile }">
		<div class="contant">
			<div class="title-45">$UPTICK Airdrop Supplementary Campaign  Uptick Official NFT Holders </div>
			<div class="des mt-10 mb-7">In order to thank the users of the Uptick NFT Marketplace on IRISnet, a special
				campaign has been launched as a countdown for the Uptick Mainnet. If you hold the designated NFTs
				released by either Uptick Official or our Featured Creators, you will be eligible to claim the
				corresponding $UPTICK rewards after the Uptick Mainnet goes live. </div>
				<div class="title-13">The campaign is divided into three stages:</div>
			<div class="title-13-bold">Collection Stage</div>
			<div class="title-13-normal">This stage has ended, and the snapshot was taken on the 24th April at 7:00 (UTC+0)</div>
			<div class="title-13-bold">Claiming Stage</div>
			<div class="title-13-normal ">Claiming starts on the 26th of April at 7:00 (UTC+0) for a total of 10 weeks.</div>
			<div class="title-13-bold">Supplementary Stage</div>
			<div class="title-13-normal mb-6">Claiming starts on the 12nd of July  at 7:00 (UTC+0) for a total of 2 weeks.</div>
			<div class="title-13-bold">Please note</div>
			<div class="title-13-normal">·Each eligible NFT will be converted into $UPTICK tokens according to the
				rules. You can view the $UPTICK rewards corresponding to each eligible NFT during the claiming stage.
			</div>
			<div class="title-13-normal">·At the end of the collection stage, a snapshot will be taken to generate a
				list of rewards, which means you need to collect before the period ends.</div>
			<div class="title-13-normal">·Winners need to claim their rewards weekly, for a total duration of 10 weeks.
				The claimable amount is the same. Those who fail to claim within the designated time period will be
				deemed to have automatically forfeited their rewards.</div>

			<!-- 缺少uptick地址暂时隐藏 -->
			<div class="title-13-normal">·After you've submitted your claim, please kindly wait patiently for the
				$UPTICK tokens to be transferred to you. To view them, simply switch the Keplr wallet to the Uptick
				node.</div>
			<div v-if="this.mobileAddress">
					<div class="title-13-bold mt-5" >Claim Wallet Address: {{this.mobileAddress ? this.mobileAddress :'—'}}</div>
					<div class="title-13-bold mt-1" >UPTICK Receiving Address: {{this.accountInfo.address ? this.accountInfo.address :'—'}}</div>
				</div>
			<div v-else>
					<div class="title-13-bold mt-5" >Claim Wallet Address: {{this.$store.state.did ? iaabaseinfo.address :'—'}}</div>
					<div class="title-13-bold mt-1" >UPTICK Receiving Address: {{this.$store.state.did ? accountInfo.address :'—'}}</div>
				</div>

			<!-- 预热活动标题 -->
			<!-- <div class="title-20" v-if="!isMobile">We are currently in the collection stage, which means you still have a chance to collect these NFTs!</div>
     <div class="mobile-title-20" v-else>We are currently in the collection stage, which means you still have a chance to collect these NFTs!</div> -->

		</div>

		<!-- 活动进入领取阶段 -->
		<div class="claim" v-if="this.$store.state.did || this.mobileAddress">
			<div class="Unclaimed">Your Total Unclaimed: <span>{{baseInfo.unclaimedAmount || 0}}</span> UPTICK</div>
			<div class="title-30">You can receive: <span>{{baseInfo.currentAmount || 0}}</span> UPTICK</div>
			<div class="title-13-notbold mt-4">This stage is a two-week supplementary campaign for $UPTICK airdrop, providing an opportunity for the users to reclaim the unclaimed Uptick Airdrop. If you did not claim your airdrops within the designated time window, you can claim 50% of the unclaimed UPTICK during the campaign period. 
			</div>
			<div class="Phase mt-6">Supplementary Phase</div>
			<div class="time mt-3">2023-07-12 07:00 - 2023-07-26 07:00 UTC+0</div>
			<div class="d-flex flex-lg-row  flex-column align-center">
				<button class="btn mt-6" v-if="baseInfo.status == 0 && baseInfo.currentAmount>0"
					@click="claimPoint">Claim</button>
				<button class="notClaim mt-6" v-else>Claim</button>
				<div>
					<img src="@/assets/icons/icon2.png" width="27" height="27" alt="" class="ml-10 mt-6"
						@click="openRecords" v-if="installmentList != null">
					<img src="@/assets/icons/icon1.png" width="27" height="27" alt="" class="ml-6 mt-6"
						@click="openUptick()">
				</div>

			</div>
		</div>
		<div class="card" v-if="claimList.length > 0">
			<div class="claimlist ">
				<div class=" titlerow ">
					<span class="item s1">NFT you own</span>
					<span class="item s2">Amount</span>
					<span class="item s3">Total Reward</span>


				</div>
				<div class="line mt-4"></div>

				<div class="claimItem mt-4" v-for="(item,index) in claimList" :key="index">
					<div class="item d-flex flex-row">
						<div class="s1 d-flex flex-row">
							<img :src="item.src" alt="">
							<div class="name ml-4">{{item.name}}</div>
						</div>
						<div class="s2">{{item.count}}</div>
						<div class="s3">{{item.amount}} UPTICK</div>

					</div>
					<div class="line mt-4"></div>

				</div>
			</div>
		</div>
		<div v-else class="card"> </div>

		<!-- 预热活动 -->
		<!-- <div class="card" >
        <div class="cardlist d-flex flex-row flex-wrap " :class="{mobileCardList:isMobile}">
      <div
      class="itemIndex"
        v-for="(item, index) in OfficialList"
        :key="index"
      >
     <img :src="item.src" class="imgUrl" alt="" @click="toDetail(item)"> 
      <div class="nftName mt-3">{{item.name}}</div>
      </div>
      </div>

    </div> -->
		<loading :isShowLoading="isShowLoading"></loading>
		<v-dialog v-model="openRecord">
			<InstallmentRecords v-if="openRecord" :installmentList="installmentList" @getOpen="Record">
			</InstallmentRecords>
		</v-dialog>
		<v-dialog v-model="openActive">
			<ActivityClaim v-if="openActive" :baseInfo="baseInfo" :irisAddress="this.mobileAddress" :upickAddress="this.accountInfo.address" projectType=2 @getOpen="Active"></ActivityClaim>
		</v-dialog>
		<v-dialog v-model="openAlert">
			<ActivityAlert v-if="openAlert" @getOpen="Alert"></ActivityAlert>
		</v-dialog>


	</div>

</template>

<script>
	import api from "@/api"
	import {
		getFileSrc
	} from "@/utils/file";
	import Loading from "@/components/loading.vue";
	import InstallmentRecords from "@/components/popup/installmentRecords.vue";
	import ActivityClaim from "@/components/popup/activityClaim.vue";
	import ActivityAlert from "@/components/popup/activityAlert.vue";
	import {
		getAddress
	} from "../../../src/keplr/wallet";
	import {
		addUptickNet
	} from "../../../src/uptick/wallet";
	const WORK_KEY = "WORK";

	export default {
		name: "officialAirdrop",
		components: {
			Loading,
			InstallmentRecords,
			ActivityClaim,
			ActivityAlert
		},
		data: () => ({
			openRecord: false,
			openActive: false,
			openAlert: false,
			OfficialList: [],
			pageNumber: 0,
			pageSize: 20,
			totalPage: false,
			imgSrc: '',
			isShowLoading: false,
			claimList: [],
			installmentList: [],
			baseInfo: {},
			accountInfo: {},
			iaabaseinfo:{},
			mobileAddress:''



		}),
		computed: {
			isMobile: function() {
				return this.$vuetify.breakpoint.mobile;
			},

		},

		async created() {
			if (window.location.href.split('?')[1]) {
				this.mobileAddress = window.location.href.split('?')[1].split('=')[1];
				this.getUptickAddress();
			} else {
				addUptickNet();
				this.accountInfo = await getAddress(0);
				this.iaabaseinfo = await getAddress(1);
			}
			// this.getOfficialList();
			this.getClaimList();
			window.eventBus.$on('claimSuccess', this.claimSuccess);


		},

		methods: {

			openRecords() {
				this.openRecord = true
			},
			openUptick() {

				//https://uptickscan.uptick.network/#/address/uptick1r82lpp2spxdp4vy9qcaa3w2xkcp5hh2ddwmu65
				window.open('https://explorer.uptick.network/uptick-network-mainnet/account/' + this.accountInfo.address, '_blank')

			},
			async claimPoint() {
				this.openActive = true
			},
			claimSuccess() {
				console.log('claimSuccess');

				this.getClaimList();
				this.openAlert = true
			},
			Record(e) {
				this.openRecord = e
			},
			Active(e) {
				debugger
				this.openActive = e

			},
			Alert(e) {
				this.openAlert = e
			},
			toDetail(item) {
				console.log('ssssss', item);
				let query = {};
				query.contractAddress = item.contractAddress;
				if (item.useType == '1') {

					this.$router.push({
						name: "Detail",
						query
					});
				} else {
					this.$router.push({
						name: "Saledetail",
						query
					});
				}

			},
			async getClaimList() {
				this.claimList = []
				let params = {}
				if (this.mobileAddress) {
					params = {
						projectType: 2,
						address: this.mobileAddress
					}
				} else {
					params = {
						projectType: 2,
						address: this.$store.state.did
					}
				}

				let claimListData = await api.home.claimList(params);
				this.baseInfo = claimListData.data
				let claimList = this.baseInfo.airdropActivityList
				this.installmentList = this.baseInfo.airdropLists
				if (claimList != null) {
					claimList.forEach(async (v) => {
						let imgSrc = await getFileSrc(WORK_KEY, v.imgUrl);
						this.claimList.push({
							src: imgSrc,
							name: v.content,
							amount: v.amount,
							count: v.count
						});
					});
				}
			},
			async getUptickAddress() {
				let params = {
					authenticationAddress: this.mobileAddress,
					did: this.mobileAddress
				}
				let uptickData = await api.home.getUptickAddress(params);
				if (uptickData && uptickData.success) {
					this.accountInfo.address = uptickData.data
					console.log(this.accountInfo)
				}
			},
			async getOfficialList() {
				this.isShowLoading = true
				let resList = await api.home.getOfficialList();

				for (let i = 0; i < resList.data.length; i++) {
					if (resList.data[i].imgUrl) {
						this.imgSrc = await getFileSrc(WORK_KEY, resList.data[i].imgUrl);

					}

					this.OfficialList.push({
						src: this.imgSrc,
						name: resList.data[i].name,
						useType: resList.data[i].useType,
						contractAddress: resList.data[i].address,
					});

				}
				this.isShowLoading = false
			}
		},
	};
</script>

<style lang="scss" scoped>
	.contain {
		.contant {
			display: flex;
			flex-direction: column;
			justify-content: center;
			max-width: 850px;
			margin: 0 auto;

			.title-45 {
				font-family: Helvetica;
				font-size: 39px;
				font-weight: bold;
				font-stretch: normal;
				line-height: 50px;
				letter-spacing: 0px;
				color: #270645;
				margin-top: 55px;
				text-align: center;
			}

			.title-30 {
				font-family: Helvetica;
				font-size: 30px;
				font-weight: bold;
				font-stretch: normal;
				line-height: 30px;
				color: #270645;
				margin-top: 55px;
				text-align: center;
			}

			.title-20 {
				font-family: Helvetica;
				font-size: 20px;
				font-weight: bold;
				font-stretch: normal;
				line-height: 30px;
				letter-spacing: 0px;
				color: #270645;
				margin-top: 55px;
				text-align: center;
				width: 600px;
				margin: 60px auto 0;
			}

			.mobile-title-20 {
				font-family: Helvetica;
				font-size: 20px;
				font-weight: bold;
				font-stretch: normal;
				line-height: 30px;
				letter-spacing: 0px;
				color: #270645;
				margin-top: 55px;
				text-align: center;
				margin: 60px auto 0;

			}

			.des {
				font-family: Helvetica;
				font-size: 15px;
				font-weight: normal;
				font-stretch: normal;
				line-height: 20px;
				letter-spacing: 0px;
				color: #270645;
				// word-break: break-all;
				text-align: left;
				word-wrap: break-word
			}

			.title-13 {
				font-family: Helvetica;
				font-size: 13px;
				font-weight: normal;
				font-stretch: normal;
				line-height: 20px;
				letter-spacing: 0px;
				color: #270645;
			}

			.title-13-bold {
				font-family: Helvetica;
				font-size: 13px;
				font-weight: bold;
				letter-spacing: 0px;
				color: #270645;
			}

			.title-13-notbold {
				width: 530px;
				font-family: Helvetica;
				font-size: 13px;
				font-weight: normal;
				letter-spacing: 0px;
				color: #270645;
				text-align: center;
			}

			.title-13-normal {
				font-family: Helvetica;
				font-size: 13px;
				font-weight: normal;
				letter-spacing: 0px;
				color: #766983;
				line-height: 20px;
			}

			.title-15 {

				font-family: Helvetica;
				font-size: 15px;
				font-weight: bold;
				font-stretch: normal;
				line-height: 15px;
				letter-spacing: 0px;
				color: #270645;
			}

			// .title-30{
			//     font-family: Helvetica;
			//     font-size: 30px;
			//     font-weight: bold;
			//     font-stretch: normal;
			//     line-height: 120px;
			//     letter-spacing: 1px;
			//     color: #270645;
			// }

		}

		.card {
			max-width: 1220px;
			margin-bottom: 70px;

			.claimlist {
				width: 100%;
				margin-top: 90px;

				.line {
					width: 100%;
					height: 1px;
					border: solid 0.5px #cccccc;
				}

				.claimItem {
					.item {
						align-items: center;

						.s1 {
							width: 50%;
							align-items: center;

							img {
								width: 44px;
								height: 44px;
								object-fit: cover;
							}

							.name {
								width: 500px;
								font-family: Helvetica;
								font-size: 15px;
								font-weight: normal;
								font-stretch: normal;
								line-height: 20px;
								letter-spacing: 0px;
								color: #270645;
								overflow: hidden;
								white-space: nowrap;
								text-overflow: ellipsis;
							}

						}

						.s2 {
							width: 40%;
							text-align: center;
						}

						.s3 {
							width: 10%;
						}

					}

				}

				.titlerow {
					width: 100%;
					display: flex;
					flex-direction: row;

					.item {
						font-size: 15px;
						font-weight: bold;
						font-stretch: normal;
						line-height: 20px;
						letter-spacing: 0px;
						color: #270645;
					}

					.s1 {
						width: 50%;

					}

					.s2 {
						width: 40%;
						text-align: center;


					}

					.s3 {
						width: 10%;

					}
				}

			}

			.cardlist {

				.itemIndex {
					margin-right: 25px;
					margin-top: 25px;

					&:nth-child(5n) {
						margin-right: 0;
					}

					.imgUrl {
						width: 221px;
						height: 221px;
						border-radius: 5px;
						object-fit: cover;
						cursor: pointer;
					}

					.nftName {
						width: 221px;
						font-family: Helvetica;
						font-size: 13px;
						font-weight: bold;
						font-stretch: normal;
						line-height: 20px;
						letter-spacing: 0px;
						color: #270645;
					}
				}


			}

			.mobileCardList {
				justify-content: center;

				.itemIndex {
					margin-right: 25px;
					margin-top: 25px;

					&:nth-child(1n) {
						margin-right: 0;
					}

					.imgUrl {
						width: 221px;
						height: 221px;
						border-radius: 5px;
						object-fit: cover;
						cursor: pointer;
					}

					.nftName {
						width: 221px;
						font-family: Helvetica;
						font-size: 13px;
						font-weight: bold;
						font-stretch: normal;
						line-height: 20px;
						letter-spacing: 0px;
						color: #270645;
					}
				}

			}
		}

		.claim {
			max-width: 850px;
			margin: 0 auto;
			display: flex;
			justify-content: center;
			align-items: center;
			flex-direction: column;

			.title-13-notbold {
				width: 608px;
				font-family: Helvetica;
				font-size: 13px;
				font-weight: normal;
				letter-spacing: 0px;
				color: #270645;
				text-align: center;

			}

			.title-30 {
				font-family: Helvetica;
				font-size: 30px;
				font-weight: bold;
				font-stretch: normal;
				line-height: 30px;
				color: #270645;
				margin-top: 10px;
				text-align: center;

				span {
					color: #c500ff;
				}
			}
				.Unclaimed{
				font-family: Helvetica;
				font-size: 30px;
				font-weight: bold;
				font-stretch: normal;
				line-height: 30px;
				color: #270645;
				margin-top: 55px;
				text-align: center;

				span {
					color: #c500ff;
				}
			}

			.Phase {
				font-family: Helvetica;
				font-size: 19px;
				font-weight: bold;
				font-stretch: normal;
				line-height: 20px;
				letter-spacing: 1px;
				color: #270645;

			}

			.time {
				font-family: Helvetica;
				font-size: 13px;
				font-weight: normal;
				font-stretch: normal;
				line-height: 20px;
				letter-spacing: 0px;
				color: #270645;
			}

			.receive {
				font-family: Helvetica;
				font-size: 19px;
				font-weight: bold;
				font-stretch: normal;
				line-height: 20px;
				letter-spacing: 1px;
				color: #270645;

				span {
					color: #c500ff;
				}
			}

			.btn {
				width: 276px;
				height: 51px;
				background-color: #270645;
				border-radius: 25px;
				font-family: Helvetica;
				font-size: 23px;
				font-weight: bold;
				font-stretch: normal;
				line-height: 30px;
				letter-spacing: 0px;
				color: #ffffff;
			}

			.notClaim {
				width: 276px;
				height: 51px;
				background-color: #766983;
				border-radius: 25px;
				font-family: Helvetica;
				font-size: 23px;
				font-weight: bold;
				font-stretch: normal;
				line-height: 30px;
				letter-spacing: 0px;
				color: #ffffff;
			}



		}

		&.mobile {
			.claim {
				.title-13-notbold {
					width: 300px;
				}

				.receive {
					text-align: center;
				}


			}

			.card {
				.claimlist {
					.titlerow {
						.s1 {
							width: 30%;
						}

						.s3 {
							width: 30%;
						}
					}
				}
			}

			.card {
				.claimlist {
					.claimItem {
						.item {
							.s1 {
								width: 30%;
							}

							.s3 {
								width: 30%;
							}
						}

					}
				}
			}


		}

	}
</style>
